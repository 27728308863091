import React, { useEffect, useState } from 'react';
// import './CurriculumVitae.css';
import { settings } from '../../helper.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import Navigation from '../Navigation';


let abortController = new AbortController();
const abort = () => abortController.abort();


function CurriculumVitae(props: any) {
    const [changed, setChanged] = useState<boolean>(false);
    const [changeData, setChangeData] = useState<any>(null);

    const [curriculumVitaeDone, setExhibitionDone] = useState<boolean>(false);
    const [curriculumVitaeSaving, setExhibitionSaving] = useState<boolean>(false);

    const [curriculumVitae, setCurriculumVitae] = useState<any>(props.curriculumVitae);

    const [modal, setModal] = useState<string>('');
    const [modalNew, setModalNew] = useState<boolean>(false);

    const [search, setSearch] = useState<string>('');

    let location = useLocation();


    useEffect(() => {
        // console.log('init CurriculumVitae', curriculumVitae);

        window.scrollTo(0, 0);

        if (!curriculumVitae) {
            fetch('https://' + settings.domain + settings.directory + '/api.php',
                {
                    'body': JSON.stringify({ 'mode': 'curriculum_vitae_get', }),
                    'method': 'POST',
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    props.setCurriculumVitae(data);
                    setCurriculumVitae(data);
                    props.setLoadingPage(false);
                })
                .catch((e) => {
                    console.log('fetch failed');
                });
        }
    }, []);


    const curriculumVitaeSet = () => {
        abort();
        abortController = new AbortController();

        setExhibitionSaving(true);

        const options = {
            'body': JSON.stringify({
                'data': changeData,
                'mode': 'curriculum_vitae_set',
                'token': props.token,
            }),
            'method': 'POST',
            'signal': abortController.signal,
        };

        fetch('https://' + settings.domain + settings.directory + '/api.php', options)
            .then((response) => response.json())
            .then((data) => {
                setTimeout(() => {
                    setExhibitionSaving(false);
                    setExhibitionDone(true);
                    setTimeout(() => {
                        setExhibitionDone(false);
                        setChanged(false);

                        props.setBlur(false);
                        setModal('');
                    }, 1000);

                    props.setCurriculumVitae(changeData);
                    setCurriculumVitae(changeData);
                }, 1000);
            })
            .catch((e) => {
                console.log('fetch failed');
            });
    };


    const HelmetFrame = () => {
        return (
            <Helmet>
                <title>{'Curriculum Vitae - ' + props.info.title}</title>

                <meta name="description" content={props.info.text} />

                <meta property="og:description" content={curriculumVitae} />
                <meta property="og:image" content={'https://' + settings.domain + settings.directory + '/logo512.png?' + props.info.key} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:title" content={'Curriculum Vitae - ' + props.info.title} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={document.location.href} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:description" content={curriculumVitae} />
                <meta name="twitter:image" content={'https://' + settings.domain + settings.directory + '/logo512.png?' + props.info.key} />
                <meta name="twitter:title" content={'Curriculum Vitae - ' + props.info.title} />
                <meta name="twitter:url" content={document.location.href} />
            </Helmet>
        );
    }


    if (curriculumVitae) {
        return (
            <>
                {
                    props.info !== null
                        ? <HelmetFrame />
                        : ''
                }


                <div id="curriculum_vitae">
                    <Navigation
                        imageKey={props.imageKey}
                        scope=""
                        search={search}
                        setSearch={setSearch}
                    />


                    {
                        curriculumVitae
                            ? <div id="curriculum_vitae_frame" style={{ 'whiteSpace': 'pre-line', }}>
                                <Row>
                                    <Col sm={2} />
                                    <Col sm={8}>
                                        {curriculumVitae}
                                    </Col>
                                    <Col sm={2} />
                                </Row>

                                {
                                    props.token !== ''
                                        ? <Col sm={12} style={{ 'margin': '1rem 0 0 0', 'textAlign': 'center', }}>
                                            <ButtonGroup size="sm">
                                                <Button
                                                    onClick={() => {
                                                        setChangeData(curriculumVitae);
                                                        setModal('curriculum_vitae');
                                                        setModalNew(false);
                                                        props.setBlur(true);
                                                    }}
                                                    variant="dark"
                                                >
                                                    <span>Change</span>
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                        : ''
                                }
                            </div>
                            : <div id="no_results_found">NO RESULTS FOUND</div>
                    }
                </div >


                <Modal
                    centered
                    id="curriculum_vitae_modal"
                    onHide={() => {
                        setChanged(false);
                        props.setBlur(false);
                        setModal('');
                    }}
                    show={modal === 'curriculum_vitae'}
                >
                    <Modal.Header closeButton>
                        {/* <Modal.Title>
                            {
                                modalNew
                                    ? <span>Add New Exhibition</span>
                                    : <span>Change Exhibition</span>
                            }
                        </Modal.Title> */}
                    </Modal.Header>
                    {
                        changeData !== null
                            ? <Modal.Body>
                                <Form>
                                    <Form.Group>
                                        <Row>
                                            <Col>
                                                <Form.Control as="textarea" rows={12} value={changeData} onChange={(e: any) => { setChanged(true); setChangeData(e.target.value); }} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            : ''
                    }

                    <Modal.Footer className={props.os}>
                        <Button
                            className="loading_done"
                            disabled={!changed && !modalNew}
                            onClick={() => {
                                curriculumVitaeSet();
                            }}
                            size="sm"
                            variant="primary"
                        >
                            <span>Save Changes</span>
                        </Button>
                        <Button
                            onClick={() => {
                                setChanged(false);
                                props.setBlur(false);
                                setModal('');
                            }}
                            size="sm"
                            variant="secondary"
                        >
                            <span>Cancel</span>
                        </Button>
                    </Modal.Footer>

                    {
                        curriculumVitaeSaving
                            ? <div id="modal_fullscreen">
                                <Spinner
                                    animation="border"
                                    aria-hidden="true"
                                    as="span"
                                    role="status"
                                    variant="light"
                                />
                            </div>
                            : curriculumVitaeDone
                                ? <div id="modal_fullscreen">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                                : ''
                    }
                </Modal>
            </>
        );
    } else {
        return (
            <>
                {
                    props.info !== null
                        ? <HelmetFrame />
                        : ''
                }


                <div id="spinner" className="dark">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            </>
        );
    }
}

export default CurriculumVitae;