import React, { useEffect, useState } from 'react';
// import './Info.css';
import { settings } from '../helper.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { NavLink } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';


let abortController = new AbortController();
const abort = () => abortController.abort();


function Info(props: any) {
    const [changed, setChanged] = useState<boolean>(props.changed);
    const [changeData, setChangeData] = useState<any>(null);

    const [info, setInfo] = useState<any>(null);
    const [infoDone, setInfoDone] = useState<boolean>(false);
    const [infoSaving, setInfoSaving] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const [modal, setModal] = useState<string>('');


    useEffect(() => {
        fetch('https://' + settings.domain + settings.directory + '/api.php',
            {
                'body': JSON.stringify({ 'mode': 'info_get', }),
                'method': 'POST',
            }
        )
            .then((response) => response.json())
            .then((data) => {
                // window.scrollTo(0, document.getElementById('info')!.clientHeight + 7 * 16);

                setTimeout(() => {
                    setInfo(data);
                    props.setInfo(data);
                    props.setLoadingInfo(false);
                }, 2000);
            })
            .catch((e) => {
                console.log('fetch failed');
            });
    }, []);


    const infoSet = () => {
        abort();
        abortController = new AbortController();

        setInfoSaving(true);

        let options = {
            'body': new FormData(),
            'method': 'POST'
        };


        let crop: any = [];
        const ea = document.getElementsByClassName('crop_crop');
        if (ea) {
            for (let i = 0, li = ea.length; i < li; ++i) {
                crop.push(JSON.parse(ea[i].getAttribute('data-c')!));
            }
        }


        for (let ka in changeData) {
            switch (ka) {
                case ('crop'):
                    options.body.append('crop', JSON.stringify(crop));
                    break;

                case ('images'):
                    options.body.append('images', JSON.stringify(changeData['images']));
                    break;

                default:
                    options.body.append(ka, changeData[ka]);
                    break;
            }
        }


        options.body.append('mode', 'info_set');
        options.body.append('token', props.token);


        fetch('https://' + settings.domain + settings.directory + '/api.php', options)
            .then((response) => response.json())
            .then((data) => {
                setTimeout(() => {
                    setInfoSaving(false);
                    setInfoDone(true);

                    setTimeout(() => {
                        setInfoDone(false);
                        setChanged(false);
                        props.setChanged(false);
                        props.setBlur(false);
                        setModal('');
                    }, 1000);

                    const key = new Date().getTime();
                    props.setImageKey(key);
                    setInfo({ ...changeData, 'crop': crop, 'key': key, });
                }, 1000);
            })
            .catch((e) => {
                console.log('fetch failed');
            });
    };

    const infoImageChange = (e: any) => {
        const files = e.target.files;
        const reader = new FileReader();

        reader.onload = (e: any) => {
            setChangeData((data: any) => {
                return {
                    ...data,
                    'crop': [{
                        'h': 100,
                        'w': 100,
                        'x': 0,
                        'y': 0,
                    }], 'image': e.target.result,
                };
            });
            setChanged(true);
        }

        reader.readAsDataURL(files[0]);
    }


    const loadInfoImage = () => {
        setLoading(true);

        setChangeData(info);
        props.setBlur(true);
        setModal('info');

        let img = document.createElement('img');

        img.onload = () => {
            setLoading(false);
        };

        img.onerror = () => {
            console.log('error')
        };

        img.src = 'https://' + settings.domain + settings.directory + '/logo.png?' + info.key;
    }


    if (info !== null) {
        return (
            <>
                <div
                    className={props.cropping ? 'cropping' : ''}
                    id="info"
                >
                    <Row>
                        <Col md={1} />
                        <Col md={3} style={{ 'textAlign': 'center', }}>
                            <NavLink to={settings.directory + '/'}>
                                <Image src={'https://' + settings.domain + settings.directory + '/logo512.png?' + info.key} id="info_image" roundedCircle />
                            </NavLink>
                        </Col>
                        <Col md={4}>
                            {
                                info.title
                                    ? <h1 style={{ 'margin': '1rem 0 0.5rem 0', }}>
                                        {info.title}
                                    </h1>
                                    : ''
                            }
                            {
                                info.text
                                    ? <ul>
                                        {
                                            info.text
                                                .split("\r\n")
                                                .map((va: string, ia: number) => {
                                                    return (
                                                        <li key={ia}>{va}</li>
                                                    )
                                                })
                                        }
                                    </ul>
                                    : ''
                            }
                            {
                                info.email
                                    ? <p><a href={'mailto:' + info.email}>{info.email}</a></p>
                                    : ''
                            }
                        </Col>
                        <Col md={3}>
                            <Image src="https://ellensolbakken.no/items/nextevent.jpeg" style={{ 'maxWidth': '100%', }} />
                        </Col>
                        <Col md={1} />
                    </Row>

                    {
                        props.token !== ''
                            ? <div style={{ 'margin': '2rem 0 0 0', 'textAlign': 'center', }}>
                                <Button
                                    onClick={() => {
                                        loadInfoImage();
                                    }}
                                    size="sm"
                                    variant="dark"
                                >
                                    <span>Change</span>
                                </Button>
                            </div>
                            : ''
                    }
                </div>


                <Modal
                    centered
                    className={props.cropping ? 'cropping' : ''}
                    id="info_modal"
                    onHide={() => {
                        if (!props.cropping) {
                            setChanged(false);
                            props.setChanged(false);
                            props.setBlur(false);
                            setModal('');
                        }
                    }}
                    show={modal === 'info'}
                >
                    <Modal.Header closeButton>
                        {/* <Modal.Title>
                            <span>Change Info</span>
                        </Modal.Title> */}
                    </Modal.Header>
                    {
                        changeData !== null
                            ? <Modal.Body>
                                {
                                    loading
                                        ? <div id="item_spinner">
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                        : <Card>
                                            <div id="crop_frame">
                                                <div>
                                                    <Card.Img className="crop_image" variant="top" src={changeData.image ? changeData.image : 'https://' + settings.domain + settings.directory + '/logo.png?' + changeData.abc} />

                                                    <div className="crop_crop" style={{ 'top': changeData.crop[0].y + '%', 'left': changeData.crop[0].x + '%', 'width': changeData.crop[0].w + '%', 'height': changeData.crop[0].h + '%', }} data-c={JSON.stringify(changeData.crop[0])}>
                                                        <span className="crop_cut" onMouseDown={props.handleCropStart('mo', false)} onTouchStart={props.handleCropStart('mo', true)} />
                                                        <span className="crop_ne" onMouseDown={props.handleCropStart('ne', false)} onTouchStart={props.handleCropStart('ne', true)} />
                                                        <span className="crop_nw" onMouseDown={props.handleCropStart('nw', false)} onTouchStart={props.handleCropStart('nw', true)} />
                                                        <span className="crop_se" onMouseDown={props.handleCropStart('se', false)} onTouchStart={props.handleCropStart('se', true)} />
                                                        <span className="crop_sw" onMouseDown={props.handleCropStart('sw', false)} onTouchStart={props.handleCropStart('sw', true)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ 'padding': '0.5rem 0 0 0', 'borderTop': '1px solid rgba(0,0,0,.125)', 'textAlign': 'center', }}>
                                                <Button className="add_image" size="sm" variant="dark">
                                                    <span>Replace Image</span>
                                                    <input type="file" onChange={infoImageChange} accept=".gif, .jpg, .jpeg, .png" />
                                                </Button>
                                            </div>

                                            <Card.Body>
                                                <Form>
                                                    <Form.Group>
                                                        <Form.Label style={{ 'fontSize': '12px', }}>TITLE</Form.Label>
                                                        <Form.Control size="sm" type="text" value={changeData.title ? changeData.title : ''} onChange={(e: any) => { setChanged(true); setChangeData((data: any) => { return { ...data, 'title': e.target.value, } }); }} />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Form.Label style={{ 'fontSize': '12px', }}>TEXT</Form.Label>
                                                        <Form.Control as="textarea" rows={10} size="sm" value={changeData.text ? changeData.text : ''} onChange={(e: any) => { setChanged(true); setChangeData((data: any) => { return { ...data, 'text': e.target.value, } }); }} />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Form.Label style={{ 'fontSize': '12px', }}>EMAIL</Form.Label>
                                                        <Form.Control size="sm" type="email" value={changeData.email ? changeData.email : ''} onChange={(e: any) => { setChanged(true); setChangeData((data: any) => { return { ...data, 'email': e.target.value, } }); }} />
                                                    </Form.Group>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                }
                            </Modal.Body>
                            : ''
                    }

                    <Modal.Footer className={props.os}>
                        <Button
                            className="loading_done"
                            disabled={!changed && !props.changed}
                            onClick={() => {
                                infoSet();
                            }}
                            size="sm"
                            variant="primary"
                        >
                            <span>Save Changes</span>
                        </Button>
                        <Button
                            onClick={() => {
                                setChanged(false);
                                props.setChanged(false);
                                props.setBlur(false);
                                setModal('');
                            }}
                            size="sm"
                            variant="secondary"
                        >
                            <span>Cancel</span>
                        </Button>
                    </Modal.Footer>

                    {
                        infoSaving
                            ? <div id="modal_fullscreen">
                                <Spinner
                                    animation="border"
                                    aria-hidden="true"
                                    as="span"
                                    role="status"
                                    variant="light"
                                />
                            </div>
                            : infoDone
                                ? <div id="modal_fullscreen">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                                : ''
                    }
                </Modal>
            </>
        );
    } else {
        return (<span />);
    }
}

export default Info;
