import React, {useEffect, useRef} from 'react';
// import './Navigation.css';
import { settings } from '../helper.js'

import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


function Navigation(props: any) {
    const searchRef = useRef<HTMLInputElement>(null);

    let history = useHistory();
    let location = useLocation();


    useEffect(() => {
        let path = location.pathname.split('/');

        if (path.length > 1) {
            switch (path[1]) {
                case ('search'):
                    searchRef.current!.focus();

                    break;
            }
        }
    }, [location]);


    const slug = (data: string) => {
        return data.replace(/[^\u00C0-\u017FA-z0-9]+/g, '-').toLowerCase().trim();
    }


    const handleChange = (e: any) => {
        if (e.target.value === '') {
            history.push(settings.directory + '/' + props.scope);
        } else {
            history.push(settings.directory + '/' + props.scope + 'search/' + slug(e.target.value));
        }

        props.setSearch(e.target.value);
    }

    const handleClear = () => {
        history.push(settings.directory + '/' + props.scope);
    }


    return (
        <Navbar expand="md">
            <Navbar.Brand>
                <NavLink to={settings.directory + '/'}>
                    <Image
                        className="d-inline-block align-top"
                        roundedCircle
                        src={'https://' + settings.domain + settings.directory + '/logo512.png?' + props.imageKey}
                    />
                </NavLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
                <Nav className="mr-auto">
                    <NavLink className="nav-link" exact to={settings.directory + '/'}>Home</NavLink>
                    <NavLink className="nav-link" exact to={settings.directory + '/cv/'}>CV</NavLink>
                    <NavLink className="nav-link" exact to={settings.directory + '/exhibitions/'}>Exhibitions</NavLink>
                </Nav>
                
                {
                    props.categories && props.categories.length > 1
                        ? <NavDropdown
                            id="categories_dropdown"
                            onSelect={props.handleCategory}
                            title="Categories"
                        >
                            {
                                props.categories
                                    .map((va: string, ia: number) => {
                                        return (
                                            <NavDropdown.Item
                                                eventKey={slug(va)}
                                                key={ia}
                                            >
                                                {va}
                                            </NavDropdown.Item>
                                        )
                                    })
                            }


                        </NavDropdown>
                        : ''
                }
                {/* </Nav> */}


                <Form inline>
                    <FormControl
                        className="mr-sm-2"
                        onChange={handleChange}
                        placeholder="Search"
                        ref={searchRef}
                        type="text"
                        value={props.search}
                    />
                    {
                        props.search !== ''
                            ? <Button
                                id="search_clear"
                                onClick={handleClear}
                                variant="link"
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                            : ''
                    }
                </Form>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Navigation;
