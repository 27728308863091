import React, { useEffect, useState } from 'react';
// import './ItemLarge.css';
import { settings } from '../helper.js'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';


function ItemLarge(props: any) {
    const [carouselIndex, setCarouselIndex] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [modal, setModal] = useState<boolean>(false);


    useEffect(() => {
        // console.log('init ITEMLARGE');

        // window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setLoading(true);
    }, [props]);


    const slug = (data: string) => {
        return data.replace(/[^\u00C0-\u017FA-z0-9]+/g, '-').toLowerCase().trim();
    }


    const handleSelect = (index: any, e: any) => {
        setCarouselIndex(index);
    };


    const HelmetFrame = () => {
        return (
            <Helmet>
                <title>{(props.items[props.item].title !== '' ? props.items[props.item].title + ' - ' : '') + props.info.title}</title>

                <meta name="description" content={props.items[props.item].text !== '' ? props.items[props.item].text : props.info.text} />

                <meta property="og:description" content={props.items[props.item].text !== '' ? props.items[props.item].text : props.info.text} />
                <meta property="og:image" content={'https://' + settings.domain + settings.directory + (props.items[props.item].images.length !== '' ? '/items/cr_' + props.items[props.item].images[0] + '?' + props.items[props.item].abc : '/logo512.png?' + props.info.key)} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:title" content={(props.items[props.item].title !== '' ? props.items[props.item].title : '') + ' - ' + props.info.title} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={document.location.href} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:description" content={props.items[props.item].text !== '' ? props.items[props.item].text : props.info.text} />
                <meta name="twitter:image" content={'https://' + settings.domain + settings.directory + (props.items[props.item].images.length !== '' ? '/items/cr_' + props.items[props.item].images[0] + '?' + props.items[props.item].abc : '/logo512.png?' + props.info.key)} />
                <meta name="twitter:title" content={(props.items[props.item].title !== '' ? props.items[props.item].title : '') + ' - ' + props.info.title} />
                <meta name="twitter:url" content={document.location.href} />
            </Helmet>
        );
    }


    return (
        <>
            <HelmetFrame />


            <Row>
                <Col xs="6" className="item_left">
                    <NavLink to={settings.directory + (props.item > 0 ? '/item/' + props.items[props.item - 1].id + '/' + slug(props.items[props.item - 1].title) : '/item/' + props.items[props.items.length - 1].id + '/' + slug(props.items[props.items.length - 1].title))}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                        <div>{props.item > 0 ? props.items[props.item - 1].title || 'previous' : props.items[props.items.length - 1].title || 'previous'}</div>
                    </NavLink>
                </Col>

                <Col xs="6" className="item_right">
                    <NavLink to={settings.directory + (props.item < props.items.length - 1 ? '/item/' + props.items[props.item + 1].id + '/' + slug(props.items[props.item + 1].title) : '/item/' + props.items[0].id + '/' + slug(props.items[0].title))}>
                        <div>{props.item < props.items.length - 1 ? props.items[props.item + 1].title || 'next' : props.items[0].title || 'next'}</div>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </NavLink>
                </Col>
            </Row>


            <Row>
                <Col md={props.items[props.item].year + props.items[props.item].title + props.items[props.item].text + props.items[props.item].dimensions + props.items[props.item].price !== '' ? 8 : 12} style={{ 'textAlign': 'center', }}>
                    <Carousel
                        activeIndex={carouselIndex}
                        controls={props.items[props.item].images.length > 1}
                        indicators={props.items[props.item].images.length > 1}
                        interval={null}
                        onSelect={handleSelect}
                        slide={false}
                    >
                        {
                            props.items[props.item].images
                                .map((va: any, ia: number) => {
                                    return (
                                        <Carousel.Item key={ia}>
                                            <Image
                                                onClick={() => {
                                                    setModal(true);
                                                    props.setBlur(true);
                                                }}
                                                // onLoad={() => {
                                                //     window.scrollTo(0, document.getElementById('info')!.clientHeight + 7 * 16);
                                                // }}
                                                src={va.indexOf('.jpeg') < 0 ? va : 'https://' + settings.domain + settings.directory + '/items/' + va + '?' + props.items[props.item].abc}
                                            />
                                        </Carousel.Item>
                                    )
                                })
                        }
                    </Carousel>
                </Col>

                {
                    props.items[props.item].year + props.items[props.item].title + props.items[props.item].text + props.items[props.item].dimensions + props.items[props.item].price !== ''
                        ? <Col md="4">
                            <ul className="item_facts">
                                {
                                    props.items[props.item].year !== ''
                                        ? <li>
                                            <NavLink className="item_facts_year" to={settings.directory + '/category/' + slug(props.items[props.item].year)}>
                                                {props.items[props.item].year}
                                            </NavLink>
                                        </li>
                                        : ''
                                }

                                {
                                    props.items[props.item].title !== ''
                                        ? <li><h4>{props.items[props.item].title}</h4></li>
                                        : ''
                                }

                                {
                                    props.items[props.item].text !== ''
                                        ? <li><p style={{ 'whiteSpace': 'pre-line', }}>{props.items[props.item].text}</p></li>
                                        : ''
                                }

                                <li>
                                    {
                                        props.items[props.item].dimensions !== ''
                                            ? <span>{props.items[props.item].dimensions}</span>
                                            : ''
                                    }

                                    {
                                        props.items[props.item].price !== ''
                                            ? <span>{props.items[props.item].price}</span>
                                            : ''
                                    }

                                    {
                                        // props.items[props.item].status
                                        //     ? <span>SOLD</span>
                                        //     : ''
                                    }
                                </li>
                            </ul>
                        </Col>
                        : ''
                }
            </Row>


            <Modal
                centered
                id="image_modal"
                onHide={() => {
                    setModal(false);
                    props.setBlur(false);
                }}
                show={modal}
                size="xl"
            >
                <Modal.Header closeButton />

                {
                    modal
                        ? <Modal.Body>
                            {
                                loading
                                    ? <div className="item_image_spinner">
                                        <Spinner animation="grow" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div>
                                    : ''
                            }

                            <Carousel
                                activeIndex={carouselIndex}
                                controls={props.items[props.item].images.length > 1}
                                indicators={props.items[props.item].images.length > 1}
                                interval={null}
                                onSelect={handleSelect}
                                slide={false}
                            >

                                {
                                    props.items[props.item].images
                                        .map((va: any, ia: number) => {
                                            return (
                                                <Carousel.Item key={ia}>
                                                    <Image
                                                        onLoad={
                                                            carouselIndex === ia ?
                                                                () => { setTimeout(() => { setLoading(false); }, 100) }
                                                                : undefined
                                                        }
                                                        src={va.indexOf('.jpeg') < 0 ? va : 'https://' + settings.domain + settings.directory + '/items/cr_' + va + '?' + props.items[props.item].abc}
                                                    />
                                                </Carousel.Item>
                                            )
                                        })
                                }
                            </Carousel>
                        </Modal.Body>
                        : ''
                }

                <Modal.Footer />
            </Modal>
        </>
    );
}

export default ItemLarge;